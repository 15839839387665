.CustomMousePointer {
  height: 100vh;
    background: #010101;
    cursor: none;
    margin: 0;
    display: flex;
    /* cursor: default; */
    
    .cursor {
        /* pointer-events: none; */
        position: fixed;
        top: 0;
        left: 0;
        width: 30px;
        height: 30px;
        /* width: 80px;
        height: 80px; */
        background-color: azure;
        mix-blend-mode: difference;
        /* background-color: rgba(255, 255, 255, 0.8); */
        border-radius: 50%;
        transform: translate(-50%, -50%);
        transition: transform 0.1s ease, background-color 0.2s ease;
        z-index: 10000; 
      }
      
      .cursor2 {
        position: fixed;
        top: 0;
        left: 0;
        width: 10px;
        height: 10px;
        background-color: #f10707;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        z-index: 10001; 
      }
      
    
    .left, .right {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    
    .right {
      background: #fff;
      
      a {
        border-bottom: 2px solid #000;
      }
      
      h1, p, a {
        color: #000;
      }
    }
  }