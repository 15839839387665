@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.cursor {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    border: 1px solid black;
    transition: all 200ms ease-out;
    position: fixed;
    pointer-events: none;
    left: 0;
    top: 0;
    transform: translate(calc(-50% + 15px), -50%);
  }
  
  .cursor2 {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: black;
    opacity: .3;
    position: fixed;
    transform: translate(-50%, -50%);
    pointer-events: none;
    transition: width .5s, height .3s, opacity .3s;
  }