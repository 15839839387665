.cursor {
    position: fixed;
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
    background-color: azure;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transition: transform 0.1s ease, background-color 0.2s ease;
    z-index: 10000;
    cursor: default;

  }
  
  .blend-difference {
    mix-blend-mode: difference;
  }

  
  
  