@font-face {
    font-family: 'Nebula';
    src: url('../fonts/nebula_2/Nebula-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@import url('https://fonts.cdnfonts.com/css/sf-pro-display?weights=100,200,300,400,500,600,700');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400;700&display=swap"');
@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Sedan+SC&display=swap');

.custom-font{
    font-family: 'Nebula', Arial, sans-serif;
}

.normal-font{
  font-family: "Plus Jakarta Sans", serif;
}

.App{
  font-family: 'SF Pro Display', sans-serif;
  font-weight: 100; 
  font-style: normal;
}

.Word-Spacing{
  word-spacing: 2px;
}

.container-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #000; /* Black background */
  }

.rebs-text{
  background: rgb(8,148,255);
  background: linear-gradient(90deg, rgba(8,148,255,1) 0%, rgba(201,89,221,1) 30%, rgba(255,46,84,1) 60%, rgba(255,144,4,1) 100%);
}

  .glow-1 {
    --igradient: linear-gradient(to bottom right, #0894FF 0%, #C959DD 34%, #FF2E54 68%, #FF9004);
    position: absolute;
    z-index: 0;
    inset: 0;
    border: 0;
    filter: blur(1px);
  }
  .glow-2 {
     filter: blur(7px);
  }
  .glow-1::after {
    /** Add border */
    --bg-size: calc(100% + 14px);
      content: "";
      pointer-events: none;
      position: absolute;
      inset: 0;
      background: var(--igradient) 50% / var(--bg-size) var(--bg-size);
      border-radius: 24px;
      border: 2px solid rgba(0, 0, 0, 0);
      mask: linear-gradient(hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0)), linear-gradient(hsl(0, 0%, 100%), hsl(0, 0%, 100%));
      mask-clip: padding-box, border-box;
      mask-composite: intersect;
  }
  .glow-2::after {
    border: 5px solid rgba(0, 0, 0, 0);
  }

  .lnding-bg{
    background: rgb(172,9,55);
background: linear-gradient(60deg, rgba(172,9,55,1) 0%, rgba(43,2,10,1) 67%);
  }

  .get-strt-btn{
    background: rgb(172,9,55);
background: linear-gradient(90deg, rgba(172,9,55,1) 0%, rgba(107,5,25,1) 100%);
  }

  .get-strt-btn:hover {
    background-color: #f43f5e; /* Rose-500 */
    color: white; /* Match text color on hover */
  }

  .gradient-border-2 {
    display: inline-block;
    position: relative;
  }

  .gradient-border-2 > .gradient-button:hover {
    transform: scale(1.1); 
  }
  
  .gradient-border-2 > .gradient-button {
    z-index: 1;
    position: relative;
    padding: 10px 20px;
    background-color: black;
    border: none;
    color: white;
    font-size: 16px;
    border-radius: 30px;
    cursor: pointer;
    transition: transform 0.7s ease-in-out;
  }
  
  .gradient-border-2::before {
    content: "";
    position: absolute;
    left: -2px;
    top: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: 0;
    border-radius: 30px; /* You can adjust this for a different shape */
    transition: 0.5s ease all;
    background-image: conic-gradient(
      from var(--gradient-continues-rotation-angle, 0deg),
      #e61389,
      #f39225
    );
    animation: gradient-continues-rotation 5s infinite linear;
  }
  
  @property --gradient-continues-rotation-angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
  }
  
  @keyframes gradient-continues-rotation {
    0% {
      --gradient-continues-rotation-angle: 0deg;
    }
    100% {
      --gradient-continues-rotation-angle: 360deg;
    }
  }
  